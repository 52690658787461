.shadow-transition {
  box-shadow:  0 1.5625rem 2.8125rem rgba($gray-900, .075);
  transition: box-shadow 0.4s ease;
}

.circle-blend {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    // background: linear-gradient(165.74deg, rgba(255, 186, 39, 0.17) 10.13%, rgba(255, 222, 152, 0.17) 86.62%);
    filter: blur(117px);
    border-radius: 50%;
    width: 400px;
    height: 400px;
    z-index: -1 !important;
    top: 0;
    left: 0;
    overflow-x: hidden;
    // transform: translate(-50%, -50%, 0);
    // background-image: linear-gradient(200deg, rgba(239 , 171, 24 ,0), rgba(247, 187, 59, 0));
  }
}

.circle-blend-right {
  &::before {
    left: auto;
    right: 0;
  }
}
.circle-blend-left {
  &::before {
    right: auto;
    left: 0;
  }
}

.circle-danger {
  &:before {
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 61, 37, 0.4) 0%, rgba(255, 0, 17, 0.15) 100%);
  }
}

.circle-warning {
  &:before {
    background: linear-gradient(165.74deg, rgba(255, 186, 39, 0.25) 10.13%, rgba(255, 222, 152, 0.17) 86.62%);
  }
}

.circle-cyan {
  &:before {
    background: radial-gradient(75.86% 75.86% at 50% 50%, rgba(0, 240, 255, 0.15) 0%, rgba(0, 240, 255, 0) 100%);
  }
}
.circle-primary {
  &:before {
    background: radial-gradient(83.29% 83.29% at 50% 50%, rgba(78, 146, 249, 0.25) 0%, rgba(78, 146, 249, 0.25) 100%);
  }
}
.circle-pink {
  &:before {
    background: radial-gradient(89.01% 89.01% at 50% 50%, rgba(197, 130, 219, 0.322) 0%, rgba(195, 130, 219, 0) 100%);
  }
}


.circle {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    height: 12.5rem;
    width: 12.5rem;
    border-radius: 50%;
    z-index: -1 !important;
    top: 0;
    left: 0;
    transform: translate3d(-50%, -50%, 0);
    background-image: linear-gradient(200deg, rgba(86 , 151, 250 ,0), #4e92f94a);
  }
}


@include media-breakpoint-up(sm) {
  .square {
    position: relative;
    display: inline-block;
    &::before {
      position: absolute;
      content: '';
      height: 25rem;
      width: 25rem;
      border-radius: 3.8125rem;
      z-index: -1 !important;
      background-image: linear-gradient(200deg, rgba(78, 146, 249, 0.342),rgba(225, 160, 247, 0.274));
    }
  }
  
  .shadow-square-bottom {
    &::before {
      top: 5rem;
      left: 5rem;
    }
  }
  .shadow-square-top {
    &::before {
      top: -5rem;
      left: -5rem;
    }
  }
  
}

@include media-breakpoint-up(sm){
  .wrapper{
    position: relative;
    display: inline-block;
    &::before {
      position: absolute;
      content: '';
      z-index: -1 !important;
    }
  }
  
  .shadow-square-right{
    &::before {
      height: 19.75rem;
      width: 12.5rem;
      top: 0.375rem;
      left: 1.25rem;
      border-radius: 1.5625rem 3.125rem 1.5625rem 3.125rem;
      background-image: linear-gradient(200deg, rgba(78, 146, 249, 0.034),rgba(78, 146, 249, 0.642));
      
    }
  }
  .shadow-square-left {
    &::before {
      height: 19.75rem;
      width: 12.5rem;
      top: 0.375rem;
      right: 1.25rem;
      border-radius: 1.5625rem 1.5625rem 3.125rem 1.5625rem;
      background-image: linear-gradient(200deg, rgba(225, 160, 247, 0.034),rgba(225, 160, 247, 0.642));
    }
  }
  
  .shadow-square-left-bottom {
    &::before {
      height: 100%;
      width: 100%;
      top: 16%;
      right: 12%;
      border-radius: 5rem 5rem 5rem 8.75rem;
      background-image: linear-gradient(200deg, rgba(78, 146, 249, 0.034),rgba(78, 146, 249, 0.642));
    }
  }
  .shadow-square-bottom {
    &::before {
      height: 25rem;
      width: 25rem;
      border-radius: 3.8125rem;
      top: 16%;
      right: 12%;
      // border-radius: 5rem 5rem 5rem 8.75rem;
      background-image: linear-gradient(200deg, rgba(78, 146, 249, 0.034),rgba(78, 146, 249, 0.642));
    }
  }
  .shadow-square-top {
    &::before {
      height: 25rem;
      width: 25rem;
      border-radius: 3.8125rem;
      top: 16%;
      right: 12%;
      // border-radius: 5rem 5rem 5rem 8.75rem;
      background-image: linear-gradient(200deg, rgba(78, 146, 249, 0.034),rgba(78, 146, 249, 0.642));
    }
  }
}

.feature-square {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    height: 6.25rem;
    width: 6.25rem;
    border-radius: 1.5625rem;
    z-index: -1 !important;
    top: 0;
    left: 0;
    transform: translate3d(26%, 8%, 0);
    background-image: linear-gradient(200deg, rgba(78, 146, 249, 0.342),rgba(225, 160, 247, 0.274));
  }
}

.shadow-klean{
  box-shadow: 0px 0.4375rem 1.25rem rgba(49, 69, 255, 0.13);
}


// Shadow feature icons

.icon-wrapper {
  position: relative;
  display: inline-block;
  &::before {
    position: absolute;
    content: '';
    height: 3.125rem;
    width: 3.125rem;
    border-radius: 0.9375rem;
    z-index: -1 !important;
    top: 0.375rem;
    left: 1.25rem;
  }
}


.shadow-danger {
  &:before {
    background-image: linear-gradient(200deg, rgba(235,100,83, 0.134), rgba(235, 100, 83, 0.134));
  }
}

.shadow-primary {
  &:before {
    background-image: linear-gradient(200deg, rgba(78, 146, 249, 0.134), rgba(78, 146, 249, 0.134));
  }
}
.shadow-warning {
  &:before {
    background-image: linear-gradient(200deg, rgba(239, 171, 24, 0.134), rgba(239, 171, 24, 0.134));
  }
}
.shadow-success {
  &:before {
    background-image: linear-gradient(200deg, rgba(150, 209, 33, 0.134), rgba(150, 209, 33, 0.134));
  }
}

.shadow-secondary {
  &:before {
    background-image: linear-gradient(200deg, rgba(196, 109, 227, 0.134), rgba(196, 109, 227, 0.134));
  }
}

.shadow-info {
  &:before {
    background-image: linear-gradient(200deg, rgba(33, 212, 223, 0.134), rgba(33, 212, 223, 0.134));
  }
}



//

.circle-blend-right-feature::before {
  left: auto;
  right: 0;
  margin-top: 16.938rem;
}

.circle-blend-left-feature::before {
  right: auto;
  left: -14.375rem;
  margin-top: 3.75rem;
}

.circle-blend-left-product::before {
  right: auto;
  left: -7.188rem;
  margin-top: 18.75rem;
}

.circle-blend-right-dedicated::before {
  right: 0;
  left: auto;
  margin-bottom: 18.75rem;
}

.circle-blend-left-project::before {
  right: auto;
  left: 0;
  margin-top: 37.5rem;
}